<template>
  <div class="proposal">
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-inner-header
      type="invoice"
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :template="true"
      redirect="/templates/invoices"
    />
    <v-add-tax-modal
      :show.sync="isShowTaxModal"
      @close="closeTaxModal"
      @saved="savedTaxModal"
    />

    <v-video-modal
      name="overview"
      title="Invoice template"
      description="A video to help you get started."
      step="invoice-template"
      thumbnail="templates.png"
      embed="5TAOA-km4JQ"
      :show.sync="isShowTutorialVideoModal"
      @close="closeTutorialVideoModal"
    />

    <div>
      <div class="flex mb-10">
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-else
          class="w-full"
        >
          <div class="bg-grey-black px-4 mt-16">
            <div class="max-w-xl mx-auto text-white pt-6">
              <div class="flex">
                <div class="w-full">
                  <h1 class="text-xl text-white mb-3">
                    Template Invoice options
                  </h1>
                  <p class="text-grey-darkerlight mb-3">
                    Set the due date, tax rate, discount and other invoice options.
                  </p>
                  <div class="input-group">
                    <input
                      v-model="name"
                      type="text"
                      class="w-full"
                      name="name"
                      placeholder="Name of the template invoice"
                    >
                  </div>

                  <div class="my-5">
                    <div
                      class="options-item flex-wrap flex items-center mb-3"
                    >
                      <div class="wselect-container mr-5">
                        <select
                          v-model="currency"
                          style="padding-right:37px;"
                        >
                          <option
                            v-for="cur in currencies"
                            :key="cur.id"
                            :value="cur"
                          >
                            {{ cur.name }}
                          </option>
                        </select>
                      </div>
                      <div
                        v-if="hasTaxes"
                        class="w-full md:w-auto mt-3 sm:mt-0 inline-block"
                      >
                        <v-checkbox
                          id="isTaxEnable"
                          v-model="isTaxEnable"
                          :checked="isTaxEnable"
                          label="Apply tax"
                          type="dark"
                        />
                        <div
                          v-show="isTaxEnable"
                          class="inline-block ml-8"
                        >
                          <v-row class="items-center">
                            <v-col>
                              <div class="select-container">
                                <select v-model="isTaxPriceIncluded">
                                  <option
                                    :value="true"
                                  >
                                    Tax included
                                  </option>
                                  <option
                                    :value="false"
                                  >
                                    Tax excluded
                                  </option>
                                </select>
                              </div>
                              <v-help-tooltip
                                :options="tooltipContent"
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <div
                        v-else
                        class="inline-block"
                      >
                        <a
                          class="cursor-pointer text-green underline"
                          @click="showTaxModal"
                        >
                          Add a tax class
                        </a>
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div>
                        <span class="text-blue-washed-grey">Valid period</span>
                        <div class="select-container ml-5">
                          <select v-model="validPeriod">
                            <option value="14">
                              14 days
                            </option>
                            <option value="30">
                              30 days
                            </option>
                            <option value="60">
                              60 days
                            </option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <v-tutorial-button
                          mode="dark"
                          @click="showTutorialVideoModal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="step-1"
            class="mt-10 mx-4"
          >
            <div class="bg-white max-w-xl mx-auto shadow py-8">
              <div class="w-full px-8">
                <h3>
                  Deliverables
                </h3>
                <p class="note mb-3">
                  Items that are included in the invoice, that a client has is not able to select.
                </p>
                <div class="note mb-3">
                  <strong>Tip</strong>: You can use tokens like <strong>@clientFirstName</strong> here as well. <a href="https://intercom.help/octoa/en/articles/7135447-using-tokens-in-the-octoa-tool" target="_blank">Learn more</a>.
                </div>
              </div>
              <div class="heading">
                <div class="flex px-8 w-full font-bold">
                  <div class="w-5/12">
                    Item
                  </div>
                  <div class="w-7/12 px-2 hidden lg:flex flex-grow">
                    <div class="flex w-1/2">
                      <div class="pr-2">
                        Quantity
                      </div>
                      <div
                        v-if="isTaxEnable"
                        class="px-2 w-20"
                      >
                        Tax
                      </div>
                      <div class="px-2">
                        Price
                      </div>
                    </div>
                    <div class="w-1/2 px-2 flex justify-between">
                      <div>
                        % Due
                      </div>
                      <div class="text-right">
                        Total
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Required list items -->
              <div class="px-4">
                <!-- Draggable -->
                <draggable
                  :list="listofItems"
                  handle=".drag"
                  drag-class="drag-helper"
                  animation="200"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                  >
                    <div
                      v-for="(item, index) in listofItems"
                      :key="item.id"
                    >
                      <div class="draggable-item">
                        <div class="drag">
                          <div class="bg">
                            <img
                              src="@/assets/img/icons/scroll.svg"
                              width="15"
                            >
                          </div>
                        </div>
                        <div
                          class="delete"
                          @click="removeItem(index)"
                        >
                          <div class="bg">
                            <img
                              src="@/assets/img/icons/delete.svg"
                              width="25"
                            >
                          </div>
                        </div>

                        <div
                          class="quoteItem flex flex-wrap w-full px-2 pt-6 pb-2"
                          style="background:transparent;"
                        >
                          <div class="w-full lg:w-5/12 px-2">
                            <vue-autosuggest
                              :ref="'autoSuggestId'+item.id"
                              :suggestions="suggestions"
                              :input-props="{id:'autosuggest__input',
                                             initialValue: item.name,
                                             onInputChange: autoSuggestOnInputChange,
                                             autocomplete: 'off',
                                             placeholder:'Item name'}"
                              @blur="autoSuggestBlurHandler(item)"
                              @click="autoSuggestClickHandler(item)"
                              @selected="autoSuggestSelectHandler"
                            >
                              <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                              </template>
                            </vue-autosuggest>

                            <textarea-autosize
                              v-model="item.description"
                              placeholder="Item description"
                              class="lg:hidden block mt-3 mb-3"
                              :min-height="100"
                              :max-height="300"
                            />
                          </div>

                          <div class="w-7/12 hidden lg:flex ">
                            <div class="flex w-1/2">
                              <div class="px-2">
                                <input
                                  v-model="item.quantity"
                                  type="number"
                                  min="0"
                                  class="quantity"
                                  name="quantity"
                                  @input="checkQuantity(item, $event)"
                                >
                              </div>
                              <div
                                v-if="isTaxEnable"
                                class="px-2 w-20"
                              >
                                <div class="select-container">
                                  <select v-model="item.tax">
                                    <option
                                      v-for="tax in taxes"
                                      :key="tax.id"
                                      :value="tax.value"
                                      :selected="(item.tax == tax.value) ? 'selected' : ''"
                                    >
                                      {{ tax.value }}%
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="px-2 w-36">
                                <vue-numeric
                                  v-model="item.price"
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  class="small-right"
                                  :currency="currency.sign"
                                  :minus="true"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                />
                              </div>
                            </div>
                            <div class="px-2 w-1/2 flex justify-between">
                              <div class="flex items-center">
                                <div class="w-18">
                                  <vue-numeric
                                    v-model="item.due_percentage"
                                    :precision="2"
                                    :max="100"
                                    class="w-18"
                                  />
                                </div>&nbsp; %
                              </div>
                              <div class="mt-3 pr-2 text-right break-words">
                                <vue-numeric
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  read-only
                                  read-only-class="font-bold text-base"
                                  :currency="currency.sign"
                                  :minus="false"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                  :min="0"
                                  :value="calculateUnitPrice(item)"
                                />
                              </div>
                            </div>
                          </div>

                          <!-- Mobile -->
                          <div class="flex flex-wrap lg:hidden justify-between w-full pb-5 px-2">
                            <div class="w-1/2 mb-3 pr-3">
                              <div class="font-bold mb-1">
                                Quantity
                              </div>
                              <input
                                v-model="item.quantity"
                                type="number"
                                min="0"
                                class="quantity"
                                name="quantity"
                                @input="checkQuantity(item, $event)"
                              >
                            </div>
                            <div
                              v-if="isTaxEnable"
                              class="w-1/2 "
                            >
                              <div class="font-bold mb-1">
                                Tax
                              </div>
                              <div class="select-container">
                                <select v-model="item.tax">
                                  <option
                                    v-for="tax in taxes"
                                    :key="tax.id"
                                    :value="tax.value"
                                    :selected="(item.tax == tax.value) ? 'selected' : ''"
                                  >
                                    {{ tax.value }}%
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div
                              class="w-1/2"
                              :class="{'pr-3' : isTaxEnable}"
                            >
                              <div class="font-bold mb-1">
                                Price
                              </div>
                              <vue-numeric
                                v-model="item.price"
                                :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                :precision="2"
                                class="small-right"
                                :currency="currency.sign"
                                :minus="true"
                                :decimal-separator="currency.decimal_separator"
                                :thousand-separator="currency.thousand_separator"
                                :min="0"
                              />
                            </div>
                            <div :class="{ 'w-full' : !isTaxEnable, 'w-1/2' : isTaxEnable}">
                              <div class="font-bold mb-1">
                                Total
                              </div>
                              <div class="mt-4">
                                <vue-numeric
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  read-only
                                  :currency="currency.sign"
                                  :minus="false"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                  :min="0"
                                  :value="calculateUnitPrice(item)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="flex px-4">
                          <div class="w-4/5 lg:w-full">
                            <label class="pt-2 py-3 block text-grey-dark">
                              <textarea-autosize
                                v-model="item.description"
                                placeholder="Item description"
                                class="hidden lg:block mt-3 mb-3"
                                :style="[isTaxEnable ? {'max-width': '708px'} : {'max-width': '620px'}]"
                                :min-height="100"
                                :max-height="300"
                              />

                              <v-checkbox
                                :id="`item${item.id}-isTemplate`"
                                v-model="item.isTemplate"
                                :checked="item.isTemplate"
                                classes="small"
                                label="Save item for future use"
                              />
                            </label>
                          </div>
                          <div class="w-1/5 text-right">
                            <div
                              class="block lg:hidden"
                              @click="removeItem(index)"
                            >
                              <img
                                src="@/assets/img/icons/delete.svg"
                                width="25"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="mt-3 mx-4">
                          <hr>
                        </div>
                      </div>
                    </div>
                  </transition-group>
                </draggable>
                <!-- End Draggable -->
              </div>

              <!-- Sub total -->
              <div class="flex flex-wrap px-8 mt-10 pb-3">
                <div class="w-3/5">
                  <a
                    class="underline green text-sm mt-3 mb-5 cursor-pointer"
                    @click="addItem"
                  >
                    + Add new item
                  </a>
                </div>
                <div class="w-full mt-8 lg:mt-0 lg:w-2/5 text-base flex items-center flex-wrap ">
                  <div
                    class="w-1/2 "
                  >
                    Discount
                  </div>
                  <div
                    class="w-1/2 items-center text-right"
                  >
                    <vue-numeric
                      v-model="discount"
                      :precision="2"
                      class="small-right"
                      :minus="false"
                      :min="0"
                    /> &nbsp; %
                  </div>

                  <hr
                    class="my-5"
                  >
                  <div class="w-1/2 text-grey-darker">
                    Subtotal <span v-if="isTaxEnable && !isTaxPriceIncluded">(excl. tax)</span>
                  </div>
                  <div class="w-1/2 text-right font-bold">
                    <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="subTotal"
                    />
                  </div>


                  <div
                    v-for="(tax, key) in taxClasses"
                    v-if="isTaxEnable"
                    :key="key"
                    class="w-full flex flex-wrap mt-5"
                  >
                    <hr
                      v-if="isTaxEnable"
                      class="mb-4"
                    >
                    <div
                      v-if="isTaxEnable"
                      class="w-1/2"
                    >
                      Tax {{ key }}%
                    </div>
                    <div
                      v-if="isTaxEnable"
                      class="w-1/2 text-right"
                    >
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="tax"
                      />
                    </div>
                  </div>


                  <hr class="my-5 thick">
                  <div class="w-1/2 text-xl font-bold">
                    Total <span v-if="isTaxEnable && isTaxPriceIncluded">(incl. tax)</span>
                  </div>
                  <div class="w-1/2 text-xl font-bold text-right">
                    <vue-numeric
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      read-only
                      :currency="currency.sign"
                      :minus="false"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                      :value="total"
                    />
                  </div>
                  <!-- <hr
                    class="my-4"
                  > -->
                  <!-- <div class="w-1/2 text-lg font-bold">
                    Due
                  </div>
                  <div class="w-1/2 text-lg font-bold text-right">
                    <vue-numeric
                      v-model="dueTotal"
                      :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                      :precision="2"
                      :currency="currency.sign"
                      :minus="false"
                      class="text-right"
                      :decimal-separator="currency.decimal_separator"
                      :thousand-separator="currency.thousand_separator"
                      :min="0"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap mt-5 mb-10 max-w-xl mx-auto text-right">
            <div class="w-full mx-4 lg:mx-0">
              <v-button
                v-show="!isSaving"
                @click="save"
              >
                Save
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'
import { applyDrag, calculateEachTaxClassPrice, calculateTaxTotal} from '@/utils/helpers'
import { addDays, format } from 'date-fns'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  data(){
    return {
      metaTitle: 'Loading',
      hasTaxes: false,
      taxes: {},
      currency:{
        sign: '€'
      },
      isSaving: false,
      isSaved: false,
      loading: true,
      invoiceId: null,
      name: '',
      isTaxEnable: false,
      isTaxPriceIncluded: false,
      taxPercent: 21,
      validPeriod: 60,
      discount: 0,
      dueTotal: 0,
      selectedListId: null,
      suggestions: [],
      listofItems: [],
      notes: '',
      editorSettings: {
        placeholder: '',
        modules: {
          toolbar: {
            container: '#toolbar', // the top level div in WysiwygToolbar has an id="toolbar"
          },
        },
      },
      isShowTaxModal: false,
      user: auth.user(),
      tooltipContent: {
        content: `
          *Various countries and cases have a different way of calculating tax. For example, in Europe when servicing consumers your prices have to be including tax. When in the US the tax is added on top of the price.
        `
      },
      invoiceNumberToolTipContent: {
        content: `
          Invoice number will be generated based on the last invoice you have sent. Check this off if you want to manually input a new invoice number.
        `
      },
      isShowTutorialVideoModal: false,
      isAutoGenerateInvoiceNumber: true,
      currencies: null,
    }
  },
  computed: {
    taxClasses(){
      let results = calculateEachTaxClassPrice(this.listofItems, this.originalSubTotal, this.discountedTotal, this.isTaxPriceIncluded)
      return results
    },
    taxTotal(){
      let results = calculateTaxTotal(this.listofItems, this.originalSubTotal, this.discountedTotal, this.isTaxPriceIncluded)
      return results
    },
    allowPaymentSchedule(){
      if(this.isInvoiceGenerated){
        this.isPaymentScheduleActive = true
        return true
      }
      this.isPaymentScheduleActive = false
      return false
    },
    discountTotal(){
      let total = 0
      total = this.originalSubTotal * (this.discount/100)
      return total
    },
    discountedTotal(){
      let total = 0
      if(this.discountTotal > 0){
        total = this.originalSubTotal - this.discountTotal
      }

      return total
    },
    originalSubTotal(){
      return this.listofItems.reduce(function (total, item) {
        return total + (item.price*(item.due_percentage/100))*item.quantity
      }, 0)
    },
    subTotal(){
      let originalSubTotal = this.listofItems.reduce(function (total, item) {
        return total + (item.price*(item.due_percentage/100))*item.quantity
      }, 0)

      let subTotal = (originalSubTotal - this.discountTotal).toFixed(2)

      if(this.isTaxEnable && this.isTaxPriceIncluded){
        let tax = parseFloat(this.taxTotal).toFixed(2)
        return subTotal - tax
      }

       return subTotal
    },
    total(){
      let total = this.originalSubTotal - this.discountTotal

      if(this.isTaxEnable && !this.isTaxPriceIncluded){
        total += Number(this.taxTotal)
       }

      return total
    },
  },
  async mounted(){

    // Load settings
    this.currency = this.user.companySettings.currency
    this.taxes = this.user.companySettings.taxes
    if(this.taxes){
      if(this.taxes[0] !== undefined){
        this.hasTaxes = true
        this.taxPercent = this.taxes[0].value
      }
    }

    try {
      const { data } = await this.$api.get('currency').list()
      this.currencies = data.currencies
    } catch(e){
      this.$toasted.global.api_error(e)
      logException(e)
    }

    this.isTaxPriceIncluded = (this.user.companySettings.includeTaxInPrice == 0) ? false : true
    this.validUntil = addDays(new Date(), this.user.companySettings.invoiceValidPeriod)

    if(this.$route.params.templateId){
      this.loading = true
      await this.getInvoice(this.$route.params.templateId)
      this.metaTitle = `Template Invoice: ${this.name}`
    } else {
      this.metaTitle = 'New Template Invoice'
      this.loading = false
      this.addItem()
    }
  },
  methods: {
    showTutorialVideoModal(){
      this.isShowTutorialVideoModal = true
    },
    closeTutorialVideoModal(){
      this.isShowTutorialVideoModal = false
    },
    calculateUnitPrice(item){
      return (item.price*(item.due_percentage/100))*item.quantity
    },
    autoSuggestBlurHandler(payload){
      let name = this.$refs['autoSuggestId'+payload.id][0].searchInput
      this.listofItems.find(function(item){
        if(item.id == payload.id) {
          item.name = name
        }
      })
    },
    async autoSuggestOnInputChange(text, oldText){
      if (text === null) {
        /* Maybe the text is null but you wanna do
        *  something else, but don't filter by null.
        */
        return
      }
      let that = this
      this.suggestions = []

      try {

        const postData = {
          companyId: this.user.company.id,
          name: text,
        }
        const { data } = await this.$api.get('templateProduct').search(postData)
        that.suggestions = [{ data: data.results }]

      } catch(e){
        logException(e)
      }
    },
    autoSuggestClickHandler(item) {
      this.selectedListId = item.id
    },
    autoSuggestSelectHandler(payload){
      let that = this
      let suggestedItem = payload.item

      this.listofItems.find(function(item){
        if(item.id == that.selectedListId) {
          item.name = suggestedItem.name
          item.price = suggestedItem.price
          item.description = suggestedItem.description
          item.unit = suggestedItem.unit
          item.quantity = suggestedItem.quantity
          item.isTaxEnable = suggestedItem.is_taxable
          item.due_percentage = 100
          item.tax = suggestedItem.tax
        }
      })
    },
    dateFormat(value) {
      if(value == null){
        return '-'
      }

      let date = format(value, 'YYYY-MM-DD')
      return date
    },
    addItem() {
      this.listofItems.push({
        id: new Date().getTime(),
        name: '',
        description: '',
        quantity: 1,
        price: 0,
        isTaxEnable: this.isTaxEnable,
        isTemplate: false,
        tax: this.taxPercent,
        due_percentage: 100,
        isHover:false,
      })
    },
    removeItem(index){
      if(this.listofItems.length > 0) {
        this.listofItems.splice(index, 1)
      }
    },
    save() {
      if(this.name == ''){
        this.$toasted.global.general_error({
          message : 'Please fill in a template name.'
        })
        return false
      }

      if(this.total < 0){
        this.$toasted.global.general_error({
          message : 'Total cannot be less than 0.'
        })
        return false
      }

      this.doSave()
    },
    async doSave() {

      this.saving = true

      try {
        const postData = {
          companyId: this.user.company.id,
          invoiceId: this.invoiceId,
          data: this._data,
          name: this.name,
          currencyId: this.currency.id,
          validPeriod: this.validPeriod,
          taxPercent: this.taxPercent,
          subTotal: this.subTotal,
          taxTotal: this.taxTotal,
          discountTotal: this.discountTotal,
          total: this.total,
          dueTotal: this.dueTotal,
        }

        const { data } = await this.$api.get('templateInvoice').updateOrCreate(postData)

        this.invoiceId = data.invoice.id
        this.isSaved = true
        this.saving = false

        this.$toasted.global.general_success({
          message : 'Invoice template updated.'
        })

      } catch(e){
        logException(e)
      }
    },
    async getInvoice(id){

      this.loading = true
      try {
        const { data } = await this.$api.get('templateInvoice').detail(this.user.company.id, id)

          let invoice = data.invoice

          this.currency = invoice.currency
          this.invoiceId = invoice.id
          this.name = invoice.name
          this.validPeriod = invoice.valid_period
          this.isTaxEnable = invoice.is_tax_enable
          this.isTaxPriceIncluded = invoice.is_tax_price_included
          this.isAutoGenerateInvoiceNumber = invoice.is_auto_generate_invoice_number
          this.taxPercent = invoice.tax
          this.discount = invoice.discount
          this.notes = invoice.notes
          this.loading = false

          // If tax happens to be deleted reset
          if(!this.hasTaxes){
            this.isTaxEnable = false
          }

          let that = this
          invoice.items.forEach(function(item) {
            item.isTaxEnable = (!that.hasTaxes) ? false : item.is_taxable
            that.listofItems.push(item)
          })
      } catch(e){
        this.$router.push('/templates/invoices')
        logException(e)
      }
    },
    showTaxModal(){
      this.isShowTaxModal = true
    },
    closeTaxModal(){
      this.isShowTaxModal = false
    },
    async savedTaxModal(taxes, defaultTaxPercent){
      this.isShowTaxModal = false
      this.isTaxEnable = true
      this.taxes = taxes
      this.hasTaxes = true
      this.taxPercent = defaultTaxPercent
      this.$modal.hide('addTaxModal')
    },
    checkQuantity(item, $event){
      let value = $event.target.value
      if(value < 0){
        item.quantity = 0
      }
      if(value > 1000){
        item.quantity = 1000
      }
    },
  },
}
</script>
